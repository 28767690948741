import React from 'react';
import Logo from 'components/Logo';
import styles from './Header.module.css';
import {Text} from "../Common/Text";

type Props = {
    centered?: boolean;
}

const Header = ({centered}: Props) => <header className={styles.header}>
    <div className={styles.headerContainer}>
        <div className={styles.logo}>
            <Logo isOnboarding={false} centered={centered}/>
        </div>
        <div className={styles.contactUs}>
            <Text type="medium-text" color="text-secondary-subdued">Are you a stylist?</Text>&nbsp;
            <a href={'mailto:help@' + process.env.REACT_APP_DOMAIN}  >
                <Text type="medium-text" bold>Contact Us</Text>
            </a>
        </div>
    </div>
</header>;

export default Header;
