import React from 'react';
import PageWrapper from "../../components/PageWrapper";
import styles from './Landing.module.css'
import {Text} from "../../components/Common/Text";
import SocialProof from './img/social-proof.png'
import Phone from './img/phone.png'
import {Button} from "../../components/Common/Button";

const Landing = () => (
    <PageWrapper isHeaderVisible={true} isFooterVisible={true}>
        <div className={styles.landingWrapper}>
            <div className={styles.top}>
                <div className={styles.left}>
                    <Text type="h1" mb={44} mt={24}>We're here to assist with your everyday style dilemmas</Text>
                    <a href={'https://quiz.' + process.env.REACT_APP_DOMAIN}>
                        <Button text="Take the style quiz" mb={24}/>
                    </a>
                </div>
                <div className={styles.right}>
                    <img src={SocialProof} alt="Social Proof"/>
                </div>
            </div>
            <div className={styles.middle}>
                <div className={styles.left}>
                    <Text type="h1" mb={24}>Get your personalized style improvement plan!</Text>
                    <Text type="medium-text" color="text-secondary-subdued" mb={44}>Wondering which clothes complement
                        your appearance? Struggling to pair your items into trendy outfits? Unsure about buying a
                        specific item? We can help!</Text>
                    <a href={'https://quiz.' + process.env.REACT_APP_DOMAIN}>
                        <Button text="Take the style quiz" mb={24}/>
                    </a>
                </div>
                <div className={styles.right}>
                    <img src={Phone} alt="Phone"/>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.left}>
                    <Text type="xlarge-text" mb={60}>Do you know how to enhance our service?
                        Are you a stylist looking for new opportunities?
                        Let's collaborate!</Text>
                    <a href={'mailto:help@' + process.env.REACT_APP_DOMAIN}>
                        <Button text="Countact Us"/>
                    </a>
                </div>
            </div>
        </div>
    </PageWrapper>
);

export default Landing;
