import React, {ReactNode} from 'react';
import styles from './PageWrapper.module.css'
import TabBar from "../TabBar/TabBar";
import Header from "../Header";
import Footer from "../Footer/Footer";

type Props = {
    children: ReactNode;
    isHeaderVisible?: boolean;
    isTabBarVisible?: boolean;
    isFooterVisible?: boolean;
}

function PageWrapper({children, isHeaderVisible = false, isTabBarVisible = false, isFooterVisible = false}: Props) {
    return <>
        <div className={styles.pageWrapper}>
            {isHeaderVisible && <Header/>}
            {children}
            {isTabBarVisible && <TabBar/>}

        </div>
        {isFooterVisible && <Footer/>}
    </>
}

export default PageWrapper;
