import React from 'react';
import {Text} from 'components/Common/Text';
import PageWrapper from "../../components/PageWrapper";
import DocList from "../../components/DocList";

const TermsOfUse = () => (
    <PageWrapper isHeaderVisible={true}>
        <Text center type="h1" mb={12}>Terms of Use & Service</Text>
        <Text type="h5" mb={12}>1. Acceptances of these terms</Text>
        <Text mb={12}>These Terms of Use & Service (the “Terms” or the “Agreement”) are an agreement between you, as a
            user of the website: {process.env.REACT_APP_DOMAIN} (“Website”), and {process.env.REACT_APP_LEGAL_COMPANY_NAME},
            {process.env.REACT_APP_LEGAL_ADDRESS}
            help@{process.env.REACT_APP_DOMAIN} (“{process.env.REACT_APP_NAME}”, or “we”/“us”).</Text>
        <Text mb={12}>These Terms govern your access to and use of the Website and the associated services offered
            through this Website.</Text>
        <Text mb={12}>Browsing the Website, its use or viewing the information in it constitutes the acceptance of all
            the terms of the Terms. In case you do not agree with any condition of the Terms, please immediately close
            the Website and stop any use of it.</Text>
        <Text mb={12}>All terms and policies, which may be adopted or introduced by us from time to time, including but
            not limited to Subscription Terms, Privacy Policy and Cookie Policy constitute an integral part of these
            Terms and are incorporated hereto by reference.</Text>
        <Text mb={12}>We may change the Terms by updating and posting them on this page of the Website. Changes shall
            automatically be effective upon publication. We may notify you specifically about some critical changes but
            are not obliged to do so in every case. Your use of the Website (after any changes to the Terms are
            published) means that you accept such changes to the Terms. After getting notice of changes of the Terms, if
            you do not object and opt-out of the amended Terms within fourteen (14) days, the amended version of the
            Terms is binding upon you.</Text>
        <Text mb={12}><strong>PLEASE NOTE:</strong></Text>
        <Text mb={12}>THE TERMS CONTAIN A BINDING ARBITRATION PROVISION IN SECTION 13 THAT AFFECTS YOUR RIGHTS HEREUNDER
            WITH RESPECT TO ALL SERVICE(S). THE ARBITRATION PROVISION REQUIRES THAT DISPUTES BE RESOLVED IN ARBITRATION
            ON AN INDIVIDUAL BASIS. IN ARBITRATION, THERE IS NO JUDGE OR JURY AND THERE IS LESS APPELLATE REVIEW THAN IN
            COURT.</Text>
        <Text mb={12}>FURTHERMORE, THE TERMS OF USE & SERVICE CONTAIN DISCLAIMERS OF WARRANTIES, LIMITATIONS OF
            LIABILITY, AND A CLASS ACTION WAIVER.</Text>
        <Text type="h5" mb={12}>2. Service</Text>
        <Text mb={12}>{process.env.REACT_APP_NAME} is a style and fashion platform with interactive content in various
            formats (courses, lookbooks, news, reviews, video, reels) from users and creators. To utilize the service,
            users may be required to set up an account.</Text>
        <Text type="h5" mb={12}>3. USER ACCOUNTS</Text>
        <Text type="h6" mb={12}>3.1. Eligibility</Text>
        <Text mb={12}>To create an account on {process.env.REACT_APP_NAME}, you shall be at least 18 years old (or of
            the reciprocal age of majority in your state or jurisdiction) and able to form legally binding contracts. We
            do not knowingly collect personal information from children under 18 years of age.</Text>
        <Text type="h6" mb={12}>3.2. Registration</Text>
        <Text mb={12}>In the course of registration of an account, you need to provide us a valid email address, and/or
            other information as prompted by the registration form or as required by applicable law. We may also allow
            you to register by using your social network credentials.</Text>
        <Text type="h6" mb={12}>3.3. Account Security</Text>
        <Text mb={12}>You are solely responsible for maintaining the confidentiality of your account, your password and
            for restricting access to your computer, and you will be solely responsible for all acts or omissions that
            occur under your account. You will immediately notify {process.env.REACT_APP_NAME} of any unauthorized use
            of your password or account. You should only create one account on the Website.</Text>
        <Text mb={12}>Unless expressly permitted in writing by {process.env.REACT_APP_NAME}, you may not sell, rent,
            lease, share, or provide access to your account to anyone else, including without limitation, charging
            anyone for access to your account. {process.env.REACT_APP_NAME} reserves all available legal rights and
            remedies to prevent its unauthorized use, including, but not limited to, technological barriers, IP mapping,
            and, in serious cases, directly contacting your Internet Service Provider (ISP) regarding such unauthorized
            use.</Text>
        <Text mb={12}>You agree to keep your contact and billing information (including but not limited to email
            address) true, accurate, complete and up-to-date, and to comply with all billing procedures, including
            providing and maintaining accurate and lawful billing information for
            active {process.env.REACT_APP_NAME} accounts.</Text>
        <Text type="h5" mb={12}>4. Subscription, payments and refunds</Text>
        <Text type="h6" mb={12}>4.1. Subscription</Text>
        <Text mb={12}>The service is offered on a subscription basis for a fee - subscription. The subscription renews
            automatically until you cancel. By purchasing a subscription, you agree that you will be charged the
            applicable subscription fee at the beginning of your subscription period and on each renewal date thereafter
            until you cancel, at the then-current subscription fee.</Text>
        <Text mb={12}>The options for the subscription period(-s) duration may be set at the discretion
            of {process.env.REACT_APP_NAME} and change from time to time. It is crucial to review the payment page for
            the applicable subscription terms. For instance, the subscription period may be of the following duration:
            one month, three months or six months.</Text>
        <Text mb={12}>All relevant and applicable fees will be clearly stated on the payment page, which we advise
            reading carefully. We may charge any of the fees in a single transaction or in a number of separate
            transactions.</Text>
        <Text mb={12}>We may also from time to time offer discounted offers that automatically renew at the full price
            without a discount after the end of the discount period. Such offers may include, but not limited to,
            introductory offers, when you set up an account and initiate your first subscription with us.</Text>
        <Text mb={12}>The prices may vary depending on your region and other factors. Please always pay attention to the
            prices stated on the payment page.</Text>
        <Text mb={12}>Additionally, {process.env.REACT_APP_NAME} may offer temporary promotional discounts at its full
            discretion, including special pricing for users who are signing up for the first time.
            Furthermore, {process.env.REACT_APP_NAME} may provide a trial subscription option.
            However, {process.env.REACT_APP_NAME} is under no obligation to offer such trials and does not guarantee
            their availability.</Text>
        <Text type="h6" mb={12}>4.3. Payment method</Text>
        <Text mb={12}>Payments will be charged to the payment method you provided at the time of purchase at the payment
            page (after entering your payment method details). You authorize us (or our third-party payment processor)
            to automatically charge the applicable subscription fees on each renewal date to the payment method that you
            provided until you cancel your subscription.</Text>
        <Text mb={12}>You authorize {process.env.REACT_APP_NAME} to supply your payment details to a third-party payment
            provider for processing your payments. Your credit/debit card provider may charge you currency conversion
            fees and other charges for processing your payments.</Text>
        <Text type="h6" mb={12}>4.4. Cancellation</Text>
        <Text mb={12}>Your subscription renews automatically at the end of each subscription period until you cancel. To
            avoid being charged, cancel your subscription before the end of the then-current period.</Text>
        <Text mb={12}>You can cancel your subscription by contacting our customer support team via the email of our
            Support Team indicated at the end of the Terms below.</Text>
        <Text mb={12}>You will be responsible for all subscription fees (plus any applicable taxes and other charges)
            incurred for the then-current subscription period. If you cancel, your right to use the Website will
            continue until the end of your then-current subscription period and will then terminate without further
            charges.</Text>
        <Text mb={12}>We may cancel your subscription if you fail to pay for your subscription, violate these Terms, or
            for any other reason in our sole discretion.</Text>
        <Text type="h6" mb={12}>4.5. Refunds</Text>
        <Text mb={12}>Refunds are provided in accordance with the provisions of Money-Back Guarantee policy.</Text>
        <Text type="h5" mb={12}>5. Representations and warranties from and restricted activities of users</Text>
        <Text type="h6" mb={12}>5.1. Representations and Warranties from Users</Text>
        <Text mb={12}>By using the Website, you represent and warrant that you:</Text>
        <DocList>
            <li>agree to be bound by the Terms,</li>
            <li>are over the age of eighteen (18) years old (or of the reciprocal age of majority in your state or
                jurisdiction),
            </li>
            <li>are neither located in a sanctioned country nor a prohibited person,</li>
            <li>have the right, authority, and capacity to enter into this Agreement and abide by all of the terms and
                conditions of this Agreement,
            </li>
            <li>agree and acknowledge that by using the Website you are accepting a benefit that cannot be disgorged,
                and
            </li>
            <li>will not be engaged in any kind of activities that are prohibited.</li>
        </DocList>
        <Text type="h6" mb={12}>5.2. Prohibited Activities of Users</Text>
        <Text mb={12}>This is a list of activities that we prohibit on the Website:</Text>
        <DocList>
            <li>You shall not create duplicate profiles;</li>
            <li>You shall not upload, post, email, transmit or otherwise make available to other Users any chain letters
                or junk email, commercial advertisements, or any other form of commercial solicitation;
            </li>
            <li>You shall not "stalk" or otherwise harass any person on the Website;</li>
            <li>You shall not post, transmit, distribute, or reproduce in any way any copyrighted material, trademarks,
                or other proprietary information without obtaining the prior consent of the owner of such proprietary
                rights;
            </li>
            <li>You shall not remove any copyright, trademark, or other proprietary rights notices contained on the
                Website;
            </li>
            <li>You shall not interfere with or disrupt the Website or the servers or networks connected to the
                Website;
            </li>
            <li>You shall not post, email, or otherwise transmit any material that contains software viruses or any
                other computer code, files, or programs designed to interrupt, detrimentally interfere with, damage,
                destroy, or limit the functionality of any computer software or hardware or telecommunications equipment
                or surreptitiously intercept or expropriate any system, data, or personal information;
            </li>
            <li>You shall not make illegal and/or unauthorized uses of the Website, including, but not limited to,
                collecting usernames and/or email addresses of other Users posted on the Website by any means for the
                purpose of sending unsolicited emails and/or unauthorized framing of, or linking to, the Website;
            </li>
            <li>You shall not "frame" or "mirror" any part of the Website, without {process.env.REACT_APP_NAME}'s prior
                written authorization. You also shall not use metatags or code or other devices containing any reference
                to {process.env.REACT_APP_NAME} or the Website in order to direct any person to any other website for
                any purpose;
            </li>
            <li>You shall not modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile, or
                otherwise disassemble any portion of the Website or any software used on or for the Website or cause
                others to do so;
            </li>
            <li>You shall not post or otherwise transmit any pornographic materials;</li>
            <li>You shall not attempt to interfere with, harm, steal from, or gain unauthorized access to the Website,
                User accounts, or the technology and equipment supporting the Website;
            </li>
            <li>You shall not impersonate another person, or misrepresent your affiliation with a person or entity;</li>
            <li>You shall not disclose personal information about another person or harass, abuse, or post or otherwise
                transmit objectionable material;
            </li>
            <li>You shall not post or otherwise transmit advertising or marketing links or content, except as
                specifically allowed by this Agreement;
            </li>
            <li>You shall not conduct any kind of advertising to, or solicitation of, from one User to other Users on
                the Website or using information obtained via the Website, unless you have not obtained specific consent
                to it from us;
            </li>
            <li>You shall not use the Website in an unlawful or illegal way or commit an illegal act in relation to the
                Website including, without limitation, all laws associated with international money transfers;
            </li>
            <li>You shall not access the Website from a jurisdiction where it is illegal or unauthorized;</li>
            <li>You shall not create any databases, websites, software, legal entities, and/or services that compete
                with the Website;
            </li>
            <li>You shall not access or use (or attempt to access or use) the Website in any way that violates this
                Agreement;
            </li>
            <li>Your use of the Website shall not create liability for us or cause us to lose (in whole or in part) the
                services of our ISPs or other suppliers; and
            </li>
            <li>You may not resell or make any commercial use of our system or the content on the Website
                without {process.env.REACT_APP_NAME}’s prior written consent.
            </li>
        </DocList>
        <Text mb={12}>Although {process.env.REACT_APP_NAME} cannot monitor the conduct of its Users while not on the
            Website, it is also a violation of these Terms to use any information obtained from the Website in order to
            contact, advertise to, solicit, or sell to any User without their prior consent explicit consent or to
            harass, abuse, or harm another person.</Text>
        <Text type="h5" mb={12}>6. Use of contacts and interaction with customer service</Text>
        <Text type="h6" mb={12}>6.1. Electronic Communication</Text>
        <Text mb={12}>Without limiting other provisions of this Agreement (including the Privacy Policy), by using the
            Website, you agree to accept and consent to receiving electronic communications initiated from us regarding
            any issue arisen from this Agreement and Website.</Text>
        <Text mb={12}>All information communicated on the Website is considered electronic communication. When you
            communicate with us through or on the Website or via other forms of electronic media, such as e-mail, you
            are communicating with {process.env.REACT_APP_NAME} electronically.</Text>
        <Text mb={12}>You agree that we may communicate electronically with you and that such communications, as well as
            notices, disclosures, agreements, and other communications that we provide to you electronically, are
            equivalent to communications in writing and shall have the same force and effect as if they were in writing
            and signed by the party sending the communication.</Text>
        <Text type="h6" mb={12}>6.2. Telephone Calls and Text Messages</Text>
        <Text mb={12}>Communications from {process.env.REACT_APP_NAME}, including its agents, representatives,
            affiliates, or business partners, may include but are not limited to: operational communications concerning
            your account or use of the Website including account verification and message notifications, updates
            concerning new and existing features of the Website, marketing information and promotions run by us, our
            agents, representatives, affiliates, or business partners, any news
            concerning {process.env.REACT_APP_NAME} and any other purposes related to the Website or required to enforce
            these Terms.</Text>
        <Text mb={12}>By entering your telephone number into the Website, you expressly agree to receive communications
            at that number as required for the purposes identified above from us, our agents, representatives,
            affiliates, or business partners, via e-mail, SMS or text messages, phone calls, and push notifications. You
            further expressly agree that these communications, including phone calls, SMS or text messages, may be
            generated using automated technology, such as an automatic telephone dialing system, or artificial or
            prerecorded voice, and even if your telephone number(s) is listed on any Do-Not-Call lists. We are not
            responsible for the timeliness or final delivery of the communication, as we cannot control work of the
            cellular telephone operator or other networks.</Text>
        <Text mb={12}>We will use your mobile number in accordance with our Privacy Policy. You represent that for the
            telephone number(s) that you have provided to us, you are the current subscriber or customary user and that
            you have the authority to provide the consent described above to be contacted at such number(s). You agree
            to promptly alert us whenever you stop using a particular telephone number. Standard charges may apply to
            the receipt of these calls or text messages. You are responsible for those charges.</Text>
        <Text mb={12}>You acknowledge that you are not required to consent to receive promotional texts or calls as a
            condition of using the Website or the services provided by non-{process.env.REACT_APP_NAME} entities. Please
            note that consent is not a condition of using our Website and consent may be revoked at any time. However,
            opting out may impact your use of the Website.</Text>
        <Text mb={12}>We may monitor or record telephone conversations that we have with you or anyone acting on your
            behalf regardless you call us or we call you. We will use the results of monitoring and recording in
            accordance with our Privacy Policy.</Text>
        <Text type="h6" mb={12}>6.3. Receipt of Special Offers and Other Communications</Text>
        <Text mb={12}>By accepting these Terms, you agree to receive special offers, promotional materials and other
            communications from {process.env.REACT_APP_NAME} according to the terms of the Privacy Policy.</Text>
        <Text type="h6" mb={12}>6.4. Prohibited Conduct Towards Customer Service</Text>
        <Text mb={12}>When communicating with our customer service representatives, you agree not to be abusive,
            obscene, profane, offensive, sexist, threatening, harassing, racially offensive, and behaving
            inappropriately anyhow.</Text>
        <Text mb={12}>If your behavior towards any of our representatives is such as described above, we may terminate
            your membership and cancel your subscription.</Text>
        <Text type="h5" mb={12}>7. Privacy</Text>
        <Text mb={12}>We respect your privacy and the use and protection of your personal information. Your submission
            of personal information through the Website is governed by our Privacy Policy. It contains important
            information and disclosures relating to the collection and use of your personal information by us.</Text>
        <Text type="h5" mb={12}>8. SUSPENSION OR TERMINATION OF MEMBERSHIP</Text>
        <Text type="h6" mb={12}>8.1. General Grounds for Termination of Account</Text>
        <Text mb={12}>Customers may terminate their accounts at their sole discretion and at any time by written notice
            via email of our Support Team indicated at the end of the Terms below. Terminations typically will be
            effective within seven business days after our receipt of your termination notice.</Text>
        <Text mb={12}>We may block or terminate your use of the Website, cancel your subscription and/or modify or
            discontinue any portion or all of the Website at any time, at our sole discretion, for any or no reason and
            without notice.</Text>
        <Text mb={12}>For the purposes of these Terms:</Text>
        <DocList>
            <li>“Termination” means deletion of the account from the {process.env.REACT_APP_NAME} servers and complete
                erasure of all data related to a User’s profile.
            </li>
            <li>“Blocking” means temporary or permanent restriction of access to a User’s profile for violation of these
                Terms.
            </li>
            <li>The decision regarding termination or blocking of the account is made each time by the Customer Service
                at its sole discretion.
            </li>
        </DocList>
        <Text mb={12}>In particular, termination of account is possible as a result of its inactivity for three months,
            violation of these Terms, security reasons, etc.</Text>
        <Text type="h6" mb={12}>8.2. Termination as a Result of Death or Disability</Text>
        <Text mb={12}>If a person that was the User of the Website is no longer able to use the Website by reason of
            death or disability, such person or their legal representative or legal successor may contact us regarding
            termination of the account.</Text>
        <Text type="h6" mb={12}>8.3. Blocking of IP addresses</Text>
        <Text mb={12}>In order to protect the integrity of the Website, we reserve the right, at any time in our sole
            discretion, to block Users with certain IP addresses from accessing the Website.</Text>
        <Text mb={12}>In particular, we may block Users with IP addresses from certain jurisdictions, including, but not
            limited to, those that are subject to sanctions of the United Nations Security Council, included into the
            OFAC Sanctions List or the EU consolidated list of persons, groups and entities subject to financial
            sanctions.</Text>
        <Text type="h5" mb={12}>9. License and copyright policy</Text>
        <Text type="h6" mb={12}>9.1. Proprietary Rights</Text>
        <Text mb={12}>Unless otherwise indicated, all content, information, and other materials
            on {process.env.REACT_APP_NAME}, including, without limitation, trademarks and logos, the visual interfaces,
            graphics, design, compilation, information, software, computer code (including source code or object code),
            services, text, pictures, information, data, sound files, other files, and the selection and arrangement
            thereof (collectively, the “Materials”) are protected by relevant intellectual property and proprietary
            rights and laws. All Materials are the property of {process.env.REACT_APP_NAME} or its subsidiaries or
            affiliated companies and/or third-party licensors. Unless otherwise expressly stated in writing
            by {process.env.REACT_APP_NAME}, by agreeing to these Terms you are granted a revocable, limited,
            non-exclusive, royalty-free, non-sublicensable, non-transferrable license to access and
            use {process.env.REACT_APP_NAME}.</Text>
        <Text mb={12}>{process.env.REACT_APP_NAME} reserves all rights not expressly granted in these Terms. This
            license is subject to these Terms and does not permit you to engage in any of the following: (a) resale or
            commercial use of {process.env.REACT_APP_NAME} or the Materials; (b) distribution, public performance or
            public display of any Materials; (c) copying, modifying, reverse engineering, decompiling, disassembling,
            attempting to derive the source code of or otherwise making any derivative uses
            of {process.env.REACT_APP_NAME} or the Materials, or any portion of them; (d) use of any data mining,
            robots, or similar data gathering or extraction methods; (e) downloading (except page caching) of any
            portion of {process.env.REACT_APP_NAME}, the Materials, or any information contained in them, except as
            expressly permitted on {process.env.REACT_APP_NAME}; or (f) any use of {process.env.REACT_APP_NAME} or the
            Materials except for their intended purposes. Any use of {process.env.REACT_APP_NAME} or the Materials
            except as specifically authorized in these Terms, without the prior written permission
            of {process.env.REACT_APP_NAME}, is strictly prohibited and may violate intellectual property rights or
            other laws. Unless explicitly stated in these Terms, nothing in them shall be interpreted as conferring any
            license to intellectual property rights, whether by estoppel, implication, or other legal
            principles. {process.env.REACT_APP_NAME} can terminate this license as set out in Section 8.</Text>
        <Text type="h6" mb={12}>9.2. License</Text>
        <Text mb={12}>You grant to {process.env.REACT_APP_NAME} and its affiliates, licensees, and successors, to the
            furthest extent and for the maximum duration permitted by applicable law (including in perpetuity if
            permitted under applicable law), a non-exclusive, unrestricted, irrevocable, perpetual, worldwide,
            royalty-free, fully sublicensable (through multiple tiers) license to exercise the copyright, publicity, and
            database rights over the content, including the right to use, reproduce, display, edit, copy, modify,
            transmit, publicly perform, extract and create derivative works of, in any media now known or not currently
            known, with respect to any content.</Text>
        <Text mb={12}>You agree that any content you post on the Website, ideas, comments and testimonials you submit on
            the Website, including any content, information etc. within the above definition of the Materials, will not
            be considered confidential and may be used by {process.env.REACT_APP_NAME}, in its sole discretion, without
            any obligation to compensate for use of or to return any submitted Materials.</Text>
        <Text type="h6" mb={12}>9.3. Our Actions in Cases of Intellectual Property Rights Infringement</Text>
        <Text mb={12}>We do not tolerate infringing activity on or through the Website anyhow.</Text>
        <Text mb={12}>If we have any reasons to believe in good faith that content and/or any kind of information
            violates intellectual property rights of a third party by being made available on or through the Website,
            upon notice from an intellectual property owner or his or her agent, we:</Text>
        <DocList>
            <li>remove or disable access to such content and/or any kind of information; and</li>
            <li>remove such content and/or any kind of information uploaded to the Website by "repeat infringers".</li>
        </DocList><Text mb={12}>"Repeat infringer" is a User that has uploaded content to or through the Website and
        about whom {process.env.REACT_APP_NAME} has received more than two takedown notices compliant under applicable
        law with respect to such User content.</Text>
        <Text mb={12}>However, we may terminate the account of a User after receipt of a single notification of claimed
            infringement.</Text>
        <Text type="h6" mb={12}>9.4. Procedure for Reporting Claimed Infringement</Text>
        <Text mb={12}>If you believe that any information/materials made available on or through the Website infringe
            your intellectual property right, please promptly send a written "Notification of Claimed Infringement"
            containing the following information to the designated agent identified
            below. {process.env.REACT_APP_NAME} may share your Notification of Claimed Infringement with the User that
            is a possible infringer, and you consent to making such disclosure by {process.env.REACT_APP_NAME}. Your
            communication must include substantially the following:</Text>
        <DocList>
            <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the
                material(s) that has/have been allegedly infringed;
            </li>
            <li>Identification of the material allegedly being infringed, or, if multiple materials are covered by a
                single notification, then a representative list of such works;
            </li>
            <li>Identification of the specific material that is claimed to be infringing or to be the subject of
                infringing activity and that is to be removed or access to which is to be disabled, and information
                reasonably sufficient to permit {process.env.REACT_APP_NAME} to locate the material on the Website;
            </li>
            <li>Information reasonably sufficient to permit {process.env.REACT_APP_NAME} to contact you, such as your
                name, address, telephone number, and email address;
            </li>
            <li>A statement that you have a good faith belief that use of the material in the manner complained of is
                not authorized by the copyright or other intellectual property owner, its agent, or the law; and
            </li>
            <li>Under penalty of perjury, a statement that the information in the Notification of Claimed Infringement
                is accurate and truthful, and that you are authorized to act on behalf of the owner of an exclusive
                right that is allegedly infringed.
            </li>
        </DocList>
        <Text mb={12}>You should consult with your own lawyer and/or review applicable law regarding copyright or other
            intellectual property infringement to confirm your obligations to provide a valid notice of claimed
            infringement.</Text>
        <Text type="h5" mb={12}>10. Disclaimer of warranties</Text>
        <Text mb={12}>Except where otherwise inapplicable or prohibited by law to the fullest extent permitted by
            law, {process.env.REACT_APP_NAME} services, software, and related documentation are provided “as is” and
            without any warranty of any kind either express or implied, including, but not limited to, the implied
            warranties of merchantability and fitness for a particular purpose. No information, whether oral or written,
            obtained by you from us through this Website shall create any warranty, representation or guarantee not
            expressly stated in these Terms.</Text>
        <Text mb={12}>{process.env.REACT_APP_NAME} expressly disclaims all warranties of any kind whether express or
            implied, including, any warranty that:</Text>
        <DocList>
            <li>the Website will meet your requirements;</li>
            <li>the Website will be uninterrupted, timely, secure, or error-free;</li>
            <li>the Website and the server that makes it available are free of viruses or other harmful components;</li>
            <li>the results that may be obtained from the use of the Website, including data, will be, correct,
                accurate, timely, or reliable;
            </li>
            <li>the quality of any data or service available on the Website will meet your expectations; and</li>
            <li>any defects or errors in the Website will be corrected.</li>
        </DocList>
        <Text mb={12}>Any material obtained through the use of the Website is accessed at your own discretion and risk,
            and you will be solely responsible for any damage to your computer system or mobile device or loss of data
            that results from the use of any such material.</Text>
        <Text mb={12}>We cannot guarantee and do no promise any specific results from use of the Website. You agree also
            to take the risks of interruption of the Website for any technical reasons.</Text>
        <Text type="h5" mb={12}>11. Limitation of liability</Text>
        <Text mb={12}>Except where otherwise inapplicable or prohibited by law in no event
            shall {process.env.REACT_APP_NAME}, its parents, subsidiaries, officers, directors, shareholders, employees,
            agents, joint venturers, consultants, successors or assigns be liable for any indirect, special, incidental,
            consequential, punitive or exemplary damages (including, but not limited to, loss of business, profits,
            data, use, revenue or other economic advantage), even if advised of the possibility of such damages
            resulting from or in connection with:</Text>
        <DocList>
            <li>the use, attempted use, or the inability to use the Website;</li>
            <li>reliance on information obtained through the Website, from other Users or third parties or a linked
                site, or User’s reliance on any product or service obtained from a third party or a linked site;
            </li>
            <li>statements or conduct of any user or third party on the Website;</li>
            <li>unauthorized access to or alteration of your transmissions or data; or</li>
            <li>any other matter relating to the Website.</li>
        </DocList>
        <Text mb={12}>The limitation of damages set forth above is a fundamental element of the basis of the bargain
            between us and you. This Website and the information would not be provided without such limitations. Even
            if {process.env.REACT_APP_NAME} is found liable under any theory in no event will our liability, and the
            liability of our parents, subsidiaries, officers, directors, employees, and suppliers, to you or any third
            parties in any circumstance exceed the greater of (a) the amount of fees you pay
            to {process.env.REACT_APP_NAME} in the 12 months prior to the action giving rise to liability, or (b) USD
            100.</Text>
        <Text type="h5" mb={12}>12. Indemnification</Text>
        <Text mb={12}>To the fullest extent permitted by law you agree to indemnify and
            hold {process.env.REACT_APP_NAME}, its parents, subsidiaries, officers, directors, shareholders, employees,
            agents, joint venturers, consultants, successors and assignees harmless from and against all losses, costs,
            liabilities and expenses including reasonable attorneys’ fees, asserted by any third-party resulting from or
            in any way connected with or related to your use of or conduct on the Website and/or your breach of this
            Agreement and/or any of your representations and warranties set forth above.</Text>
        <Text mb={12}>{process.env.REACT_APP_NAME} reserves the right to assume the exclusive defense and control of any
            matter otherwise subject to indemnification by you, in which event you will fully cooperate with, and fully
            indemnify, {process.env.REACT_APP_NAME} in connection therewith.</Text>
        <Text type="h5" mb={12}>13. Dispute resolution by mandatory binding arbitration and class action waiver</Text>
        <Text mb={12}>PLEASE READ THE FOLLOWING ARBITRATION AGREEMENT IN THIS SECTION 13 ("ARBITRATION AGREEMENT")
            CAREFULLY. IT REQUIRES YOU TO ARBITRATE DISPUTES WITH {process.env.REACT_APP_NAME} AND LIMITS THE MANNER IN
            WHICH YOU CAN SEEK RELIEF FROM US.</Text>
        <Text type="h6" mb={12}>13.1. Applicability of Arbitration Agreement</Text>
        <Text mb={12}>This Arbitration Agreement governs any dispute between you and {process.env.REACT_APP_NAME} (and
            each of our respective agents, corporate parents, subsidiaries, affiliates, predecessors in interest,
            successors, and assigns) including but not limited to claims arising out of or relating to any aspect of the
            relationship between you and {process.env.REACT_APP_NAME}, whether based in contract, tort, statute, fraud,
            misrepresentation or any other legal theory; claims that arose before these Terms or any prior agreement;
            and claims that may arise after the termination of these Terms. However, (1) you may assert claims in small
            claims court if your claims qualify within the scope of your jurisdiction; and (2) you
            or {process.env.REACT_APP_NAME} may seek equitable relief in court for infringement or other misuse of
            intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and
            patents). This Arbitration Agreement shall apply, without limitation, to all claims that arose or were
            asserted before the effective date of these Terms or any prior version of these Terms.</Text>
        <Text mb={12}>The relevant arbitrator shall have sole authority to determine applicability of the Arbitration
            Agreement in each particular case. In the event that a dispute involves both issues that are subject to
            arbitration and issues that are not subject to arbitration, the parties unequivocally agree that any legal
            proceeding regarding the issues not subject to arbitration shall be stayed pending resolution of the issues
            subject to arbitration.</Text>
        <Text type="h6" mb={12}>13.2. Initial Dispute Resolution</Text>
        <Text mb={12}>Most disputes can be resolved without resort to arbitration. If you have any dispute
            with {process.env.REACT_APP_NAME}, you agree that before taking any formal action, you will contact us via
            email of our Support Team indicated at the end of the Terms below, and provide a brief, written description
            of the dispute and your contact information. The parties agree to use their best efforts to settle any
            dispute, claim, question, or disagreement directly through consultation with {process.env.REACT_APP_NAME},
            and good faith negotiations will be a condition to either party initiating an arbitration.</Text>
        <Text type="h6" mb={12}>13.3. Binding Arbitration</Text>
        <Text mb={12}>If the parties do not reach an agreed-upon solution within a period of sixty (60) days from the
            time informal dispute resolution is initiated under the Initial Dispute Resolution provision above, then
            either party may initiate binding arbitration as the sole means to resolve claims subject to the terms set
            forth below. Specifically, all claims arising out of or relating to these Terms (including the Terms
            formation, performance, and breach), the parties' relationship with each other, and/or your use of the
            Website will be finally settled by binding arbitration before one arbitrator administered by:</Text>
        <DocList>
            <li>the London Court of International Arbitration (“LCIA”) if you are not a U.S. resident. Disputes are
                subject to the most current version of the LCIA Arbitration Rules when the notice of arbitration is
                submitted. Information about the LCIA’s rules can be found at <a
                    href="https://www.lcia.org/Dispute_Resolution_Services/LCIA_Arbitration.aspx">https://www.lcia.org/Dispute_Resolution_Services/LCIA_Arbitration.aspx</a>;
                or
            </li>
            <li>JAMS if you are a U.S. resident. Disputes involving claims and counterclaims under $250,000, not
                inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the
                Streamlined Arbitration Rules and procedures available at
                http://www.jamsadr.com/rules-streamlined-arbitration/; all other claims shall be subject to JAMS’s most
                current version of the Comprehensive Arbitration Rules and Procedures, available at
                http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s rules are also available at
                [www.jamsadr.com](http://www.jamsadr.com/) or by calling JAMS at 800-352-5267.
            </li>
        </DocList>
        <Text mb={12}>In each case, the relevant arbitration rules will apply as modified by this Arbitration Agreement.
            In the event of a conflict between the applicable arbitration rules and these Terms, these Terms shall
            govern unless otherwise agreed by the parties and the relevant arbitrator.</Text>
        <Text mb={12}>If the relevant administrator of arbitration is not available to arbitrate, the parties will
            select an alternative arbitral forum.</Text>
        <Text type="h6" mb={12}>13.4. Arbitration Proceedings</Text>
        <Text mb={12}><strong>Initiating Arbitration.</strong> To start an arbitration, you must follow instructions
            available at:</Text>
        <DocList>
            <li>the London Court of International Arbitration (“LCIA”) if you are not a U.S. resident. Disputes are
                subject to the most current version of the LCIA Arbitration Rules when the notice of arbitration is
                submitted. Information about the LCIA’s rules can be found at<a
                    href="https://www.lcia.org/Dispute_Resolution_Services/LCIA_Arbitration.aspx">https://www.lcia.org/Dispute_Resolution_Services/LCIA_Arbitration.aspx</a>;
                or
            </li>
        </DocList>
        <Text mb={12}><strong>Initiating Arbitration.</strong> To start an arbitration, you must follow instructions
            available at:</Text>
        <DocList>
            <li><a
                href="https://www.lcia.org/adr-services/lcia-notes-for-parties.aspx#5.%20COMMENCING%20AN%20LCIA%20ARBITRATION"
                target="_blank"
                rel="noreferrer">https://www.lcia.org/adr-services/lcia-notes-for-parties.aspx#5.%20COMMENCING%20AN%20LCIA%20ARBITRATION</a> for
                LCIA; or
            </li>
            <li><a href="https://www.jamsadr.com/submit/" target="_blank"
                   rel="noreferrer">https://www.jamsadr.com/submit/</a> for JAMS.
            </li>
        </DocList>
        <Text mb={12}><strong>Arbitration Fees.</strong> If you are a consumer and you initiate arbitration, the only
            fee required to be paid is $250 and the other part of the filing fee (if any) will be borne by us. If the
            arbitrator finds the arbitration initiated by you to be non-frivolous and/or not in bad faith, all other
            arbitration costs will be borne by {process.env.REACT_APP_NAME}. If {process.env.REACT_APP_NAME} initiates
            arbitration against you and you are a consumer, {process.env.REACT_APP_NAME} will pay for all costs
            associated with the arbitration. The parties are responsible for paying their own attorneys’ fees unless the
            arbitration rules and/or applicable law provide otherwise.</Text>
        <Text mb={12}>Should either party bring a dispute involving issues subject to arbitration in a forum other than
            arbitration, the court or the arbitrator shall have the authority to award reasonable costs, fees and
            expenses, including reasonable attorneys’ fees, incurred by the other party in successfully staying or
            dismissing, in whole or in part, such other proceeding or in otherwise enforcing compliance with this
            Arbitration Agreement.</Text>
        <Text mb={12}><strong>Arbitrator Selection.</strong> The arbitrator must be neutral, and you will have a
            reasonable opportunity to participate in the process of choosing the arbitrator.</Text>
        <Text mb={12}><strong>Arbitration Hearings.</strong> The arbitrator will conduct hearings, if any, by
            teleconference or videoconference (based on written and/or electronic filing of documents), rather than by
            personal appearances, unless the arbitrator determines upon request by you or by us that an in-person
            hearing is appropriate. Any in-person appearances will be held at a location which is reasonably convenient
            to both parties with due consideration of their ability to travel and other pertinent circumstances,
            provided that if you are a consumer, you have a right to an in-person hearing in your hometown area. If the
            parties are unable to agree on a location, such determination should be made by the administrator of
            arbitration or by the arbitrator.</Text>
        <Text mb={12}><strong>Consumer Remedies.</strong> If you are a consumer, remedies that would otherwise be
            available to you under applicable laws will remain available under this Arbitration Agreement, unless you
            retain the right to pursue such remedies in court as per this Agreement.</Text>
        <Text mb={12}><strong>Discovery of Non-privileged Information.</strong> As part of the arbitration, both you and
            we will have the opportunity for discovery of non-privileged information that is relevant to the
            claim.</Text>
        <Text mb={12}>Upon either party’s request, the arbitrator will issue an order requiring that confidential
            information of either party disclosed during the arbitration (whether in documents or orally) may not be
            used or disclosed except in connection with the arbitration or a proceeding to enforce the arbitration award
            and that any permitted filing of confidential information must be done under seal.</Text>
        <Text mb={12}><strong>Communications with the Arbitrator.</strong> Whenever communicating with the arbitrator,
            the parties must include each other – for example, by including the other party on a telephone conference
            call and copying the other party on any written submissions, such as letters or emails. To the extent
            practicable, conferences with the arbitrator will take place by telephone conference call or email. Ex parte
            communications are not permitted with any arbitrator.</Text>
        <Text mb={12}><strong>Choice of Law.</strong> The arbitrator shall apply:</Text>
        <DocList>
            <li>if you are not a U.S. resident, the laws of England and Wales (also known as English Law), without
                regard to English Law’s conflict of laws rules; or
            </li>
            <li>if you are a U.S. resident, Delaware law consistent with the Federal Arbitration Act and applicable
                statutes of limitations, and shall honor claims of privilege recognized at law.
            </li>
        </DocList>
        <Text mb={12}><strong>Arbitrator’s Award.</strong> An arbitrator’s award will consist of a written statement
            stating the disposition of each claim. The award will also provide a concise written statement of the
            essential findings and conclusions on which the award is based.</Text>
        <Text mb={12}>The arbitration award shall be final and binding on the parties. Judgment on the arbitrator’s
            award may be entered in any court of competent jurisdiction.</Text>
        <Text type="h6" mb={12}>13.5. Class Action Waiver and Jury Trial Waiver</Text>
        <Text mb={12}>THE ARBITRATOR HAS NO AUTHORITY TO AWARD PUNITIVE DAMAGES. NEITHER YOU
            NOR {process.env.REACT_APP_NAME} AGREES TO ANY ARBITRATION ON A CLASS BASIS, AND THE ARBITRATOR SHALL HAVE
            NO AUTHORITY TO PROCEED ON SUCH A BASIS. A PARTY MAY ASSERT A CLAIM OR COUNTERCLAIM ONLY IN THAT PARTY’S
            INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS PROCEEDING. THE ARBITRATOR
            MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF CLASS
            PROCEEDING. UNDER THE ARBITRATION AGREEMENT, ARBITRATOR SHALL NOT COMBINE OR CONSOLIDATE MORE THAN ONE
            PARTIES CLAIMS WITHOUT THE WRITTEN CONSENT OF ALL AFFECTED PARTIES TO AN ARBITRATION PROCEEDING.</Text>
        <Text mb={12}>BY AGREEING TO THE ARBITRATION OF DISPUTES AS SET FORTH HEREIN, YOU AGREE THAT YOU ARE WAIVING
            YOUR RIGHT TO A JURY TRIAL AND LIMITING YOUR RIGHT TO APPEAL AND YOU UNDERSTAND THAT YOU ARE WAIVING YOUR
            RIGHTS TO OTHER AVAILABLE RESOLUTION PROCESSES, SUCH AS A COURT ACTION.</Text>
        <Text type="h6" mb={12}>13.6. Litigation of Intellectual Property and Small Claims Court Claims</Text>
        <Text mb={12}>Notwithstanding the parties' decision to resolve all disputes through arbitration, either party
            may bring enforcement actions, validity determinations or claims arising from or relating to theft, piracy
            or unauthorized use of intellectual property in court with jurisdiction or in other relevant state authority
            to protect its intellectual property rights. Either party may also seek relief in a small claims court for
            disputes or claims within the scope of that court's jurisdiction.</Text>
        <Text type="h6" mb={12}>13.7. 30-Day Right to Opt Out</Text>
        <Text mb={12}>You have the right to opt out and not be bound by the arbitration and class action waiver
            provisions set forth above by sending electronic notice of your decision to opt out to the email address of
            our Support Team indicated at the end of the Terms below, with the subject line, "ARBITRATION AND CLASS
            ACTION WAIVER OPT-OUT". The notice must be sent within 30 days of (a) the effective date of these Terms; or
            (b) your first date that you used the Website that contained any versions of the Terms that substantially
            included this version of the Arbitration Agreement (including class action waiver), whichever is later.
            Otherwise, you will be bound to arbitrate disputes in accordance with the terms of these paragraphs. If you
            opt out of this Arbitration Agreement, {process.env.REACT_APP_NAME} also will not be bound by it.</Text>
        <Text mb={12}>In order to be effective, the opt out notice must include your full name and clearly indicate your
            intent to opt out of binding arbitration. By opting out of binding arbitration, you are agreeing to resolve
            disputes in accordance with Section 17.1 "Governing Law and Venue."</Text>
        <Text type="h6" mb={12}>13.8. Severability of Arbitration Agreement</Text>
        <Text mb={12}>If any portion of this Arbitration Agreement is found to be unenforceable or unlawful for any
            reason, (a) the unenforceable or unlawful provision shall be severed from these Terms; (b) severance of the
            unenforceable or unlawful provision shall have no impact whatsoever on the remainder of this Arbitration
            Agreement or the parties’ ability to compel arbitration of any remaining claims on an individual basis
            pursuant to this Arbitration Agreement; and (c) to the extent that any claims must therefore proceed on a
            class, collective, consolidated, or representative basis, such claims must be litigated in court in
            accordance with Section 17.1 "Governing Law and Venue," and the parties agree that litigation of those
            claims shall be stayed pending the outcome of any individual claims in arbitration. Further, if any part of
            this Arbitration Agreement is found to prohibit an individual claim seeking public injunctive relief, that
            provision will have no effect to the extent such relief is allowed to be sought out of arbitration, and the
            remainder of this Arbitration Agreement will be enforceable.</Text>
        <Text type="h6" mb={12}>13.9. Survival</Text>
        <Text mb={12}>This Arbitration Agreement will survive any termination of your use of the Website.</Text>
        <Text type="h5" mb={12}>14. Changes to the agreement and its parties. Notices</Text>
        <Text type="h6" mb={12}>14.1. Changes to the Agreement</Text>
        <Text mb={12}>We reserve the right, at our sole discretion, to change the Agreement from time to time and at any
            time and without prior notice by:</Text>
        <DocList>
            <li>posting the changed Agreement (or parts of it) to the Website; or</li>
            <li>otherwise giving you notice of the changes.</li>
        </DocList>
        <Text mb={12}>The changes shall be effective upon such posting by us or upon us giving you such notice,
            whichever is the earlier (unless we expressly indicate otherwise).</Text>
        <Text mb={12}>It is your responsibility to check the Website and your email account periodically for changes to
            these Terms and other parts of the Agreement.</Text>
        <Text mb={12}>Your continued use of or access to the Website following the effective date of any changes to the
            Agreement constitutes acceptance of those changes. This Agreement may not be changed by you, unless any
            changes proposed by you are expressly accepted by {process.env.REACT_APP_NAME} in writing.</Text>
        <Text mb={12}>Any new features which are added to the Website shall also be subject to the Terms.</Text>
        <Text mb={12}>In this clause, the terms "change" and "changed" in relation to changes to the Agreement shall be
            interpreted broadly and shall include any and all modifications, amendments, revisions and restatements
            whatsoever, including adding or removing any portions of this Agreement.</Text>
        <Text type="h6" mb={12}>14.2. Changes to Parties</Text>
        <Text mb={12}>We may assign or transfer all of our rights and obligations hereunder to any other person, whether
            by way of novation or otherwise, and you hereby give us consent to any such assignment and transfer.</Text>
        <Text mb={12}>You agree that posting on this Website of a version of this Agreement indicating another person as
            a party to this Agreement will constitute sufficient notice to you of the transfer of our rights and
            obligations under the Agreement with you to that party (unless otherwise is expressly indicated).</Text>
        <Text type="h6" mb={12}>14.3. Notices</Text>
        <Text mb={12}>Without prejudice to the provisions of the preceding clause, we may choose to notify you of
            changes to this Agreement by posting a notice via the Website, by sending you an email, or otherwise.</Text>
        <Text mb={12}>If we choose to notify you about changes to this Agreement or about other matters by email, each
            such notification shall be effective and shall be deemed received by you immediately after being sent to the
            email address you have provided to us, even if:</Text>
        <DocList>
            <li>our email notification is filtered as a spam, junk, bulk, or other undesirable or low-priority message
                and is not displayed in your email inbox; or
            </li>
            <li>you do not actually read it for any other reason.</li>
        </DocList>
        <Text mb={12}>To reduce the chance that it is so filtered, please add the email of our Support Team indicated at
            the end of the Terms below to your email contact book and whitelist this address as a "safe" or "approved"
            sender. In addition, you may wish to create a custom filter marking emails from this address as important
            emails for your high-priority inbox. Please contact your email service provider if you are not sure how to
            do any of that.</Text>
        <Text type="h5" mb={12}>15. Term of the agreement</Text>
        <Text mb={12}>This Agreement will take full force and effect when you access the Website and will remain in
            effect while you use the Website until your account is terminated for whatever reason.</Text>
        <Text mb={12}>After your membership is terminated, all terms that by their nature may survive termination of
            this Agreement shall be deemed to survive such termination including, but not limited to, Sections
            5-18.</Text>
        <Text mb={12}>We reserve the right to take further action for our loss or the potential loss of other Users or
            third parties when necessary due to your breach of this Agreement, in our sole discretion.</Text>
        <Text type="h5" mb={12}>16. Electronic signature</Text>
        <Text mb={12}>You further acknowledge and agree that by clicking on a button labeled "CONNECT NOW", "PAY", "BUY
            WITH GOOGLE PAY", "BUY WITH APPLE PAY", "I ACCEPT", "I AGREE" or similar links or buttons, you are
            submitting a legally binding electronic signature and are entering into a legally binding contract. You
            acknowledge that your electronic submissions constitute your agreement and intent to be bound by this
            Agreement.</Text>
        <Text mb={12}>Pursuant to any applicable statutes, regulations, rules, ordinances or other laws, including
            without limitation the United States Electronic Signatures in Global and National Commerce Act, P.L. 106-229
            (the "E-Sign Act") or other similar statutes, you hereby agree to the use of electronic signatures,
            contracts, orders and other records and to electronic delivery of notices, policies and records of
            transactions initiated or completed through the Website.</Text>
        <Text mb={12}>Furthermore, you hereby waive any rights or requirements under any statutes, regulations, rules,
            ordinances or other laws in any jurisdiction which require an original signature, delivery or retention of
            non-electronic records, or to payments by other than electronic means.</Text>
        <Text type="h5" mb={12}>17. Miscellaneous</Text>
        <Text type="h6" mb={12}>17.1. Governing Law and Venue</Text>
        <Text mb={12}>Except as otherwise specified herein, this Agreement shall be governed by and construed in
            accordance with the governing law - {process.env.REACT_APP_LEGAL_COUNTRY}.</Text>
        <Text mb={12}>To the extent that any action relating to any dispute hereunder is for whatever reason not
            submitted to arbitration, each of the parties submits to the exclusive jurisdiction to the courts of {process.env.REACT_APP_LEGAL_COUNTRY} to settle any disputes which may arise out of or in connection with this Agreement and that accordingly the
            relevant proceedings must be brought in such courts.</Text>
        <Text mb={12}>The parties irrevocably submit to the personal jurisdiction and venue of the courts of {process.env.REACT_APP_LEGAL_COUNTRY} and
            waive any defenses of improper venue or forum non conveniens.</Text>
        <Text type="h6" mb={12}>17.2. Entire Agreement. Severability</Text>
        <Text mb={12}>This Agreement and all other provisions referenced herein contain the entire agreement between you
            and {process.env.REACT_APP_NAME} regarding the use of the Website. If any provision of this Agreement is
            held invalid, the remainder of this Agreement shall continue in full force and effect.</Text>
        <Text type="h6" mb={12}>17.3. No Waiver of Breach or Default</Text>
        <Text mb={12}>The failure to require performance of any provision will not affect our right to require
            performance at any time thereafter, nor will a waiver of any breach or default of this Agreement or any
            provision of this Agreement constitute a waiver of any subsequent breach or default or a waiver of the
            provision itself.</Text>
        <Text type="h6" mb={12}>17.4. Force Majeure</Text>
        <Text mb={12}>{process.env.REACT_APP_NAME} shall not be responsible for any failure to perform due to unforeseen
            circumstances or to causes beyond our reasonable control, including but not limited to: acts of God, such as
            fire, flood, earthquakes, hurricanes, tropical storms or other natural disasters; war, riot, arson,
            embargoes, acts of civil or military authority, or terrorism; strikes, or shortages in transportation,
            facilities, fuel, energy, labor or materials; failure of the telecommunications or information services
            infrastructure; hacking, spam, or any failure of a computer, server or software, for so long as such event
            continues to delay {process.env.REACT_APP_NAME}’s performance.</Text>
        <Text type="h6" mb={12}>17.5. Absence of Certain Kind of Relationship</Text>
        <Text mb={12}>You agree that no joint venture, partnership, employment, or agency relationship exists between
            you and {process.env.REACT_APP_NAME} as a result of this Agreement or use of the Website.</Text>
        <Text type="h6" mb={12}>17.6. Use of Section Headers</Text>
        <Text mb={12}>Use of Section headers in this Agreement is for convenience only and will not have any impact on
            the interpretation of particular provisions.</Text>
        <Text type="h6" mb={12}>17.7. Translations</Text>
        <Text mb={12}>Any translation from the English version is provided for your convenience only. In the event of
            any difference in meaning, version, or interpretation between the English language version of these Terms
            and any translation, the English language version will prevail.</Text>
        <Text type="h5" mb={12}>18. Contact information</Text>
        <Text mb={12}>Please contact us with any questions regarding the Terms.</Text>
        <Text mb={12}>E-mail: help@{process.env.REACT_APP_DOMAIN}</Text>
        <Text mb={12}>Attention of: Customer Support, {process.env.REACT_APP_NAME}.</Text>
        <Text mb={12}>All Rights Reserved.</Text>
    </PageWrapper>
);
export default TermsOfUse
