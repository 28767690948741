import React from 'react';
import {Text} from 'components/Common/Text'
import PageWrapper from "../../components/PageWrapper";

const SubscriptionPolicy = () => (
    <PageWrapper isHeaderVisible={true}>
        <Text center type="h1" mb={24} mt={24}>Subscription Policy</Text>

        <Text mb={12}>The service is offered on a subscription basis for a fee - subscription. The subscription renews
            automatically until you cancel. By purchasing a subscription, you agree that you will be charged the
            applicable subscription fee at the beginning of your subscription period and on each renewal date thereafter
            until you cancel, at the then-current subscription fee.</Text>

        <Text mb={12}>The options for the subscription period(-s) duration may be set at the discretion of {process.env.REACT_APP_DOMAIN} and
            change from time to time. It is crucial to review the payment page for the applicable subscription terms.
            For instance, the subscription period may be of the following duration: one month, three months, or six
            months.</Text>

        <Text mb={12}>All relevant and applicable fees will be clearly stated on the payment page, which we advise
            reading carefully. We may charge any of the fees in a single transaction or in a number of separate
            transactions.</Text>

        <Text mb={12}>We may also from time to time offer discounted offers that automatically renew at the full price
            without a discount after the end of the discount period. Such offers may include, but not be limited to,
            introductory offers when you set up an account and initiate your first subscription with us.</Text>

        <Text mb={12}>The prices may vary depending on your region and other factors. Please always pay attention to the
            prices stated on the payment page.</Text>

        <Text mb={12}>Additionally, StyleWay may offer temporary promotional discounts at its full discretion, including
            special pricing for users who are signing up for the first time. Furthermore, StyleWay may provide a trial
            subscription option. However, StyleWay is under no obligation to offer such trials and does not guarantee
            their availability.</Text>
        <Text italic mt={24}>
            {process.env.REACT_APP_LEGAL_COMPANY_NAME}<br/>
            {process.env.REACT_APP_LEGAL_ADDRESS}
        </Text>
    </PageWrapper>
);

export default SubscriptionPolicy;
