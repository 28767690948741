import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import styles from './TabBar.module.css'
import {ReactComponent as SearchIcon} from './img/search.svg'
import {ReactComponent as FavouritesIcon} from './img/favourites.svg'
import {ReactComponent as ProfileIcon} from './img/profile.svg'
import styled from "styled-components";
import classNames from "classnames";

const TabLink = styled(NavLink)`
    &.active {
        color: var(--primary-active);
    }
`;

const TabBar = () => {
    const [showTabBar, setShowTabBar] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const scrollThreshold = 70;

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollDistance = Math.abs(scrollTop - lastScrollTop);

        if (scrollDistance > scrollThreshold) {
            if (scrollTop > lastScrollTop) {
                setShowTabBar(false); // scrolling down
            } else {
                setShowTabBar(true); // scrolling up
            }
            setLastScrollTop(scrollTop);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    return <div className={classNames([styles.tabBarWrapper, showTabBar ? '' : styles.tabBarWrapperHidden])}>
        <div className={styles.tabBar}>
            <TabLink className={styles.tabLink} to="/feed" activeClassName="active">
                <SearchIcon/>
                <span>Explore</span>
            </TabLink>
            <TabLink className={styles.tabLink} to="/favourites" activeClassName="active">
                <FavouritesIcon/>
                <span>Favourites</span>
            </TabLink>
            <TabLink className={styles.tabLink} to="/profile" activeClassName="active">
                <ProfileIcon/>
                <span>Profile</span>
            </TabLink>
        </div>
    </div>
};

export default TabBar;
