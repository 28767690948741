import React from 'react';
import {Text} from 'components/Common/Text'
import PageWrapper from "../../components/PageWrapper";
import DocList from "../../components/DocList";

const CookiePolicy = () => (
    <PageWrapper isHeaderVisible={true}>
        <Text center type="h1" mb={24} mt={24}>Cookie Policy</Text>
        <Text mb={12}>This Cookie Policy (the “Policy”) explains what cookies are, what types of cookies are placed on your device when you visit StyleWay and how we use them. This policy also governs our use of similar technologies, including web beacons.</Text>
        <Text mb={12}>This Policy does not address how we deal with your personal data generally. To learn more about how we process your personal data, please see our Privacy Policy.</Text>

        <Text type="h5" mb={12}>1. What are cookies</Text>
        <Text mb={12}>Cookies are small text files that are sent to or accessed from your web browser or your device's memory. A cookie typically contains the name of the domain (internet location) from which the cookie originated, the “lifetime” of the cookie and a randomly generated unique number or similar identifier. A cookie also may contain information about your device, such as user settings, browsing history and activities conducted while using StyleWay.</Text>
        <Text mb={12}>There are different types of cookies:</Text>

        <Text type="h6" mb={12}>1.1 Session cookies</Text>
        <Text mb={12}>Session cookies expire at the end of your browser session. StyleWay uses these cookies to help you use our website more efficiently, by allowing us to learn more about how you use our website during one single browser session.</Text>

        <Text type="h6" mb={12}>1.2 Persistent cookies</Text>
        <Text mb={12}>Persistent cookies are not automatically deleted when you close your browser. These types of cookies are used in order to help you sign-in to our website quickly and for analytical purposes.</Text>

        <Text type="h6" mb={12}>1.3 First-party cookies</Text>
        <Text mb={12}>First-party cookies are placed on your device directly by us. These cookies are used to adapt our website to your browser’s language preferences and to better understand your use of our website.</Text>

        <Text type="h6" mb={12}>1.4 Third-party cookies</Text>
        <Text mb={12}>Third-party cookies are placed on your device by our service providers, which help us provide you both a satisfying and safe experience on our website. We do not have control over cookies placed on your device by third parties. Generally, we use the following types of third-party cookies: security cookies, analytical cookies, and advertising cookies.</Text>

        <Text mb={12}>For example, we work with the following service providers that use third-party cookies on our website:</Text>
        <DocList>
            <li><strong>Google (via Google Analytics)</strong><br/>
                Google Analytics is a Google service that uses cookies and other data collection technologies to collect information about your use of StyleWay in order to report website trends. You can opt out of Google Analytics by visiting <a href="https://www.google.com/settings/ads" target="_blank">www.google.com/settings/ads</a> or by downloading the Google Analytics opt-out browser add-on at <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.
            </li>
            <li><strong>Microsoft</strong><br/>
                Microsoft uses cookies to assess the effectiveness of advertisements placed on our website.
            </li>
            <li><strong>Zendesk</strong><br/>
                Zendesk is a third-party software that provides customer support management. Zendesk uses cookies to help us properly respond to customer requests in a timely manner.
            </li>
            <li><strong>Cloudflare</strong><br/>
                Cloudflare uses cookies to identify trusted web traffic to our website.
            </li>
        </DocList>
        <Text mb={12}>We do not permit any of the above service providers to collect any of your personal data on StyleWay to utilize for their own purposes. These service providers are subject to confidentiality agreements with us and/or other legal restrictions on their use or collection of any personal information. Third-party cookies are covered by the third-parties' privacy policies. Therefore, should you have any questions regarding the use of cookies by third parties, please contact them directly.</Text>
        <Text mb={12}>For more information on third-party advertising cookies, interest-based advertising, and how to opt-out of that practice by companies participating in industry self-regulation, please visit the relevant websites, such as Digital Advertising Alliance or Interactive Digital Advertising Alliance.</Text>
        <Text type="h5" mb={12}>2. Similar technologies</Text>
        <Text type="h6" mb={12}>2.1 Web Beacons</Text>
        <Text mb={12}>Web beacons are graphic images (also known as “pixel tags”) that contain a unique identifier enabling us to recognize when someone has visited StyleWay or opened an e-mail that we or our service provider have sent them.</Text>
        <Text type="h6" mb={12}>2.2 Software development kits (SDKs)</Text>
        <Text mb={12}>SDKs are small pieces of code included in apps, which function like cookies and web beacons.</Text>
        <Text type="h6" mb={12}>2.3 Snap Pixels</Text>
        <Text mb={12}>The Snap Pixel is a piece of JavaScript code that helps our service providers measure the cross-device impact of their advertising campaigns in SnapChat. They can see how many Snapchatters take action after seeing their advertisement.</Text>
        <Text type="h5" mb={12}>3. Why do we need cookies?</Text>
        <Text mb={12}>When you visit or interact with StyleWay, we or our service providers may use cookies, web beacons, and other similar technologies in order to learn more about your interactions with our Service. The utilization of these technologies can help us provide you with a better, faster, and safer experience.</Text>
        <Text type="h5" mb={12}>4. How we use cookies</Text>
        <Text mb={12}>Cookies play an important role in helping us provide a personal, effective, and safe environment on StyleWay.</Text>
        <Text mb={12}>As we make improvements to our website, our use of cookies may vary. Generally, we use cookies for the following purposes:</Text>
        <Text type="h6" mb={12}>Categories of use</Text>
        <DocList>
            <li><strong>Authentication</strong><br/>
                We use cookies to remember your login credentials so that you are able to log in to our site quickly.
            </li>
            <li><strong>Security</strong><br/>
                We use cookies to enable and support our security features, and to help us detect malicious activity, monitor for and detect potentially harmful or illegal use of StyleWay.
            </li>
            <li><strong>Analytics and Research</strong><br/>
                These cookies help us understand how StyleWay is being used and to help us customize, improve, and research our features and services.
            </li>
            <li><strong>Advertising</strong><br/>
                These cookies are used to make our advertising messages more relevant to you. They perform functions like preventing the same advertisement from continuously re-appearing and ensuring that advertisements are properly displayed.
            </li>
        </DocList>
        <Text type="h5" mb={12}>5. How can you manage cookies?</Text>
        <Text mb={12}>Most browsers allow you to control cookies through their settings preferences. However, changes you make to your cookie preferences may make using our website less satisfying. In some cases, you may not be able to utilize all or part of our site.</Text>
        <Text mb={12}>Below you may find more information on how to manage cookies in your browser:</Text>
        <DocList>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noreferrer">Chrome</a></li>
            <li><a href="https://support.microsoft.com/en-us/products/windows?os=windows-10" target="_blank" rel="noreferrer">Explorer</a></li>
            <li><a href="https://support.apple.com/kb/PHeading21411" target="_blank" rel="noreferrer">Safari</a></li>
            <li><a href="https://support.mozilla.org/products/firefox/cookies" target="_blank" rel="noreferrer">Firefox</a></li>
            <li><a href="http://www.opera.com/help/tutorials/security/cookies/" target="_blank" rel="noreferrer">Opera</a></li>
        </DocList>
        <Text type="h5" mb={12}>6. Cookies Table</Text>
        <Text mb={12}>Cookies that we commonly use are listed below. This list is not exhaustive, but describes the main reasons we typically set cookies.</Text>
    </PageWrapper>
);

export default CookiePolicy;
