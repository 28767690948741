import React from 'react';
import {Text} from 'components/Common/Text'
import PageWrapper from "../../components/PageWrapper";
import DocList from "../../components/DocList";

const PrivacyPolicy = () => (
    <PageWrapper isHeaderVisible={true}>
        <Text center type="h1" mb={24} mt={24}>Privacy Policy</Text>
        <Text mb={12}>This Privacy Policy (the “Policy”) governs your use of {process.env.REACT_APP_DOMAIN} (the “Website”). This Policy explains how {process.env.REACT_APP_NAME} handles certain information, including your personal information, when you visit, use, or otherwise interact with the Service. It also describes certain legal rights you may have, subject to applicable law, and how you can exercise them.</Text>
        <Text mb={12}>By accessing, using the Service, submitting your personal information to us, or otherwise interacting with {process.env.REACT_APP_NAME}, you agree to any processing and transfer of your personal information in accordance with this Policy. Please be sure to carefully read the entirety of this Policy when using the Service.</Text>
        <Text mb={12}>For the purposes of this Policy, “personal information” and/or “personal data” refers to data about identified or identifiable individuals (such as their name, mobile phone number, email address, etc.); it may also include, where required by applicable law, the scope of the definition provided therein, e.g., as per the: 1) relevant U.S. federal or state legislation; and/or 2) the European Union’s law.</Text>
        <Text mb={12}>Check {process.env.REACT_APP_NAME} Terms of Use & Service (the “Terms”) for the meaning of defined words (those with capital letters) not explicitly defined in the Policy.</Text>
        <Text type="h5" mb={12}>1. Personal data controller</Text>
        <Text mb={12}>{process.env.LEGAL_COMPANY_NAME} with registered office at: {process.env.LEGAL_ADDRESS}, will be the controller of your personal data.</Text>
        <Text type="h5" mb={12}>2. What categories of personal data do we collect?</Text>
        <Text mb={12}>We collect data you make available to us voluntarily (for example, email address, phone number, name, date of birth). We also collect data automatically when you use the Website (for example, your IP address, device type).</Text>
        <Text type="h6" mb={12}>2.1. Data you provide to us</Text>
        <Text mb={12}>You provide us data about yourself when you register for and/or use the Website, for example, when you create a user profile (“Profile”), respond to our emails, or report a problem. The data that you provide to us includes:</Text>
        <DocList>
            <li><strong>Profile data and other materials you post on the Website.</strong> This includes your name, username, date of birth, email address, and phone number. You may also provide us your voice and image (in any content you upload) and additional data on your Profile. This may include, but is not limited to: your gender and age, other personal characteristics and demographic data, any ideas, comments, testimonials you share and post, as well as images, photographs, and videos. By providing this information you agree and understand that this data may be publicly available (unless otherwise explicitly stated in a particular case). Please do not provide personal data to your profile that you would not want to be publicly available.</li>
        </DocList>
        <Text type="h6" mb={12}>2.2. Data we collect automatically:</Text>
        <DocList>
            <li><strong>Data about how you found us.</strong> We collect data about your referring URL (that is, the place on the Web where you were when you tapped on our ad).</li>
            <li><strong>Cookies and other similar technologies.</strong> As further described in our Cookie Policy, our Website uses cookies that record data about the use of our website to distinguish you from other users. You can control cookies through your browser settings.</li>
            <li><strong>Browser and Device data.</strong> We collect data from or about the devices from which you access the Website, depending on the permissions you've granted. We may associate the data we collect from your different devices, which helps us provide consistent services across your devices. Examples of such data include: language settings, IP address, location, time zone, type and model of a device, device settings, operating system, Internet service provider, mobile carrier, hardware ID, and Facebook ID.</li>
            <li>We also collect your Apple Identifier for Advertising (“IDFA”) or Google Advertising ID (“AAID”) (depending on the operating system of your device). You can typically reset these numbers through the settings of your device's operating system (but we do not control this).</li>
            <li><strong>Transaction data.</strong> When you make payments through the Website, you need to provide financial account data, such as your credit card number, to our third-party service providers. We do not collect or store full credit card number data, though we may receive credit card-related data and data about the transaction, including: date, time and amount of the transaction, and the type of payment method used.</li>
            <li><strong>Usage data.</strong> We record how you interact with our Website. For example, we log the features, and content you interact with, how often and long you use the Website, what sections you use, and other interactions.</li>
        </DocList>
        <Text type="h5" mb={12}>3. For what purposes do we process your personal data?</Text>
        <Text mb={12}>We process your personal data:</Text>
        <DocList>
            <li><strong>to provide services to you.</strong> This includes enabling you to use the Website in a seamless manner and preventing or addressing Website errors or technical issues. For example, we use your data to authenticate you and authorize access to our Website.</li>
            <li><strong>to research and analyze your use of the Website.</strong> This helps us to better understand our business, analyze our operations, maintain, improve, innovate, plan, design, and develop the Website and our new products. We conduct surveys, research, and test features in development. We analyze the data we have to evaluate our Website and conduct audits and troubleshooting activities to improve our Website content and layouts. As a consequence, we often decide how to improve the Website based on the results obtained from this processing. For example, if we discover that users do not often use certain sections of the Website, we may focus on improving this section.</li>
            <li><strong>to customize the Website for you.</strong> We select the payment processor available to you; we may also use your data to determine your eligibility for promotions, sweepstakes, and contests.</li>
            <li><strong>to process your payments.</strong> We provide paid products and/or services within the Website. For this purpose, we use third-party services for payment processing (for example, payment processors). As a result of this processing, you will be able to make a payment for paid features of the Website.</li>
            <li><strong>to enforce the Terms and to prevent, detect, investigate, and resolve disputes and/or remediate malicious activities, fraud, other cybercrimes, take legally-required actions, or make sure you are a real person and avoid the creation of fake accounts.</strong> We use personal data to enforce our agreements and contractual commitments, to detect, prevent, and combat fraud. As a result of such processing, we may share your information with others, including law enforcement agencies (in particular, if a dispute arises in connection with the Terms).</li>
            <li><strong>to communicate with you regarding your use of our Website.</strong> We may communicate with you, for example, by email or directly on the Website, including through push notifications.</li>
            <li><strong>to show and/or send you marketing communications.</strong> We process your personal data for our marketing campaigns. We may add your email address to our marketing list. As a result, you will receive information about our Website, features, offers, promotions, contests, and events or provide other news or information about third-party services that may be of interest to you. You can opt out of advertisement and/or commercial emails by clicking here: <a href="#">unsubscribe</a>.</li>
            <li><strong>to provide you customer service and support.</strong> As a result of such processing, we will send you messages about the availability of our Website security, payment transactions, status of your orders, legal notices, or other Website-related information.</li>
            <li><strong>to personalize our ads.</strong> We and our partners use your personal data to tailor ads and possibly even show them to you at the relevant time.</li>
            <li><strong>to comply with legal obligations.</strong> We may process, use, or share your data when the law requires it, in particular, if a law enforcement agency requests your data by available legal means.</li>
        </DocList>
        <Text type="h5" mb={12}>4. Legal basis for data processing</Text>
        <Text mb={12}>The laws in some jurisdictions require providing information with respect to the legal grounds for the usage and/or disclosure of personal information. To the extent those laws apply, our legal grounds are as follows:</Text>
        <Text mb={12}><strong>A. Consent.</strong></Text>
        <Text mb={12}>Where required by law, as well as in other cases, we handle personal information on the basis of implied or express consent.</Text>
        <Text mb={12}><strong>B. To perform our contract with you.</strong></Text>
        <Text mb={12}>We process your personal information on this basis in order to:</Text>
        <DocList>
            <li>provide the services;</li>
            <li>customize your experience;</li>
            <li>communicate with you regarding your use of the Service;</li>
            <li>manage your account;</li>
            <li>provide you customer service and support;</li>
            <li>process your payments.</li>
        </DocList>
        <Text mb={12}><strong>C. Legal compliance.</strong></Text>
        <Text mb={12}>We may need to use and disclose personal information in certain ways to comply with our legal obligations.</Text>
        <Text mb={12}><strong>D. Legitimate interest.</strong></Text>
        <Text mb={12}>For our (or others’) legitimate interests unless those interests are overridden by your interests or fundamental rights and freedoms that require protection of personal data:</Text>
        <DocList>
            <li>to communicate with you regarding your use of our Service;</li>
            <li>to research and analyze your use of the Service;</li>
            <li>to send you marketing communications;</li>
            <li>to personalize our ads;</li>
            <li>to enforce our Terms of Service and to prevent and counteract fraud.</li>
        </DocList>
        <Text type="h5" mb={12}>5. Privacy Rights of the Residents of Certain States of the U.S.</Text>
        <Text mb={12}>If you are a resident of a U.S. state that has enacted applicable consumer data privacy laws, which include, but might not be limited to: California, Colorado, Connecticut, Utah and Virginia, you may be entitled to specific rights, including those regarding access to your personal information. However, please note that such laws usually have certain applicability threshold, and, thus, may not be fully applicable to {process.env.REACT_APP_NAME}. For more information, please contact us at the email address of our Support Team indicated at the end of the Policy below.</Text>
        <Text type="h5" mb={12}>6. Residents of the European Union (EU) and European Economic Area (EEA)</Text>
        <Text mb={12}>If you are a resident of a country within the European Union (EU) or the European Economic Area (EEA), you are subject to the General Data Protection Regulation (GDPR) and any other applicable laws. In particular, under the GDPR, you may enjoy specific rights and guarantees with respect to your personal information. Should you have any questions or require further information, please contact us at the email address of our Support Team indicated at the end of the Policy below.</Text>
        <Text type="h5" mb={12}>7. Data retention</Text>
        <Text mb={12}>We will retain personal data for the period necessary to fulfill the purposes outlined in the Policy unless a longer retention period is required or permitted by law.</Text>
        <Text mb={12}>Please note that we have a variety of obligations to retain the data that you provide to us, including to ensure that transactions can be appropriately processed, settled, refunded, or charged-back, to help identify fraud and to comply with anti-money laundering and other laws and rules that apply to us and to our financial service providers. Accordingly, even if you disable/delete your Profile, we will retain certain data to meet our obligations.</Text>
        <Text type="h5" mb={12}>8. With whom do we share your personal data?</Text>
        <Text mb={12}>We share information with third parties that help us operate, provide, improve, integrate, customize, support, and market our Service. We may share some sets of personal data, in particular, for purposes indicated in the Policy. The types of third parties we share information with include, in particular:</Text>
        <Text type="h6" mb={12}>8.1. Service providers</Text>
        <Text mb={12}>We share personal data with third parties that we hire to provide services or perform business functions on our behalf, based on our instructions. We may share your personal information with the following types of service providers:</Text>
        <DocList>
            <li>cloud storage providers (Google Cloud Platform, servers.com);</li>
            <li>data analytics providers (Facebook, Google, Amplitude);</li>
            <li>marketing partners (in particular, social media networks, marketing agencies, email delivery services);</li>
            <li>payment processing contractors.</li>
        </DocList>
        <Text type="h6" mb={12}>8.2. Law enforcement agencies and other public authorities</Text>
        <Text mb={12}>We may use and disclose personal data to enforce the Terms, to protect our rights, privacy, safety, or property, and/or that of our affiliates, you or others, and to respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities, or in other cases provided for by law.</Text>
        <Text type="h6" mb={12}>8.3. Third parties as part of a merger or acquisition</Text>
        <Text mb={12}>As we develop our business, we may buy or sell assets or business offerings. Customers’ information is generally one of the transferred business assets in these types of transactions. We may also share such information with any affiliated entity (e.g., parent company or subsidiary) and may transfer such information in the course of a corporate transaction, such as the sale of our business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.</Text>
        <Text type="h5" mb={12}>9. Links to Other Websites and Third-Party Content</Text>
        <Text mb={12}>We may provide links to third-party websites, services, and applications that are not operated or controlled by {process.env.REACT_APP_NAME}. This Policy does not apply to the privacy practices of those third parties. The fact that we link to a website, service, or application is not an endorsement, authorization, or representation of our affiliation with that third party. We encourage you to review the privacy policies of any third-party service before providing any personal information to or through them.</Text>
        <Text type="h5" mb={12}>10. International data transfers</Text>
        <Text mb={12}>We may transfer personal data to countries other than the country in which the data was originally collected in order to provide the Website set forth in the Terms and for purposes indicated in the Policy.</Text>
        <Text type="h5" mb={12}>11. Changes to the Policy</Text>
        <Text mb={12}>We may from time to time update this Policy to reflect changes to our practices. If such changes take place, we will post an updated version of the Policy on the Website and indicate when the revision took place. In case of material changes to this Policy, we may also notify you through the Service or by other available means.</Text>
        <Text mb={12}>By continuing to access or use the Service after those changes become effective, you agree to be bound by the revised Policy. You are advised to regularly check the Policy to stay up-to-date.</Text>
        <Text type="h5" mb={12}>12. Age limitation</Text>
        <Text mb={12}>We do not knowingly process personal data from persons under 18 years of age. If you learn that anyone younger than 18 has provided us with personal data, please contact us at the email address of our Support Team indicated at the end of the Policy below.</Text>
        <Text type="h5" mb={12}>13. Privacy rights</Text>
        <Text mb={12}>To be in control of your personal data at {process.env.REACT_APP_NAME} your rights include, but are not limited to the following:</Text>
        <DocList>
            <li><strong>To know what personal information is being collected and to correct inaccurate information (if any).</strong> You may review, edit, or change your personal data through your account settings or by emailing us at the email address of our Support Team indicated at the end of the Policy below.</li>
            <li><strong>To delete your personal data.</strong> You can request the erasure of your personal data by emailing us at the email address of our Support Team indicated at the end of the Policy below. In some cases, we may be legally required to keep some of the data for a certain time; in which case we will fulfill your request after we have complied with our obligations.</li>
            <li><strong>To object to or restrict the use of your personal data.</strong> You can ask us to stop using all or some of your personal data or limit our use thereof by sending us a request. For example, you can opt out of email marketing or restrict the use of cookies on your device.</li>
        </DocList>
        <Text mb={12}>You may submit requests through an authorized agent, in which case we will need to verify the agent’s identity, your identity, and their authority to act on your behalf before we can process the request. Depending on the applicable legislation, you may also be entitled to other/additional rights in relation to your personal data.</Text>
        <Text type="h5" mb={12}>14. How do we handle “do not track” requests?</Text>
        <Text mb={12}>Except as otherwise stipulated in the Policy, the Website does not support “Do Not Track” requests. To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.</Text>
        <Text type="h5" mb={12}>15. Contact us</Text>
        <Text mb={12}>Please contact us with any questions regarding the Policy.</Text>
        <Text mb={12}>E-mail: <a href={`mailto:help@${process.env.REACT_APP_NAME}`}>help@{process.env.REACT_APP_DOMAIN}</a></Text>
        <Text mb={12}>Attention of: Customer Support, {process.env.REACT_APP_NAME}.</Text>
        <Text mb={12}>All Rights Reserved.</Text>
    </PageWrapper>
);

export default PrivacyPolicy;
