// Utility function to convert hex color to RGBA
const hexToRgba = (hex: string): { r: number, g: number, b: number, a: number } => {
    let r = 0, g = 0, b = 0, a = 1;

    // 3 digits
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }

    return { r, g, b, a };
};

// Utility function to convert RGBA to string
const rgbaToString = ({ r, g, b, a }: { r: number, g: number, b: number, a: number }): string => {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
};

// Custom transparentize function
export const transparentize = (amount: number, color: string): string => {
    const { r, g, b, a } = hexToRgba(color);
    const newAlpha = Math.max(0, Math.min(1, a - amount));
    return rgbaToString({ r, g, b, a: newAlpha });
};
