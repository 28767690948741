import './App.css';
import {ThemeProvider} from "./utils";
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {defaultThemeConfig} from "./defaultTheme";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Landing from "./pages/Landing";
import TermsOfUse from "./pages/TermsOfUse";
import CookiePolicy from "./pages/CookiePolicy";
import SubscriptionPolicy from "./pages/SubscriptionPolicy";
import RefundPolicy from "./pages/RefundPolicy";

function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Landing/>,
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy/>,
        },
        {
            path: '/terms-of-use',
            element: <TermsOfUse/>,
        },
        {
            path: '/cookie-policy',
            element: <CookiePolicy/>,
        },
        {
            path: '/subscription-policy',
            element: <SubscriptionPolicy/>,
        },
        {
            path: '/refund-policy',
            element: <RefundPolicy/>,
        },
    ]);

    return (
        <ThemeProvider theme={defaultThemeConfig}>
            <RouterProvider router={router}/>
        </ThemeProvider>
    );
}

export default App;
